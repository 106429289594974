import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import SVG from 'react-inlinesvg'

// Render inline SVG with fallback non-svg images
export default function ImageHeading({ svg, fluid, file, alt }) {
  if (file.contentType === 'image/svg+xml') {
    if (svg && svg.content) {
      // Inlined SVGs
      return (
        <div className="imageHeading">
          <SVG src={file.url} />
        </div>
      )
    }

    // SVGs that can/should not be inlined
    return <img className="imageHeading" src={file.url} alt={alt} />
  }

  // Non SVG images
  return <GatsbyImage image={fluid} className="imageHeading" alt={alt} />;
}
